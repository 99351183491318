<template>
	<el-dialog
			top="0" class="commonDia updateMem" :close-on-click-modal="false"
			:visible.sync="dialogMem" :show-close="false"
			width="600px" :close-on-press-escape="false">
		<template #title>
			<h1 class="tit">{{ statusText }}会员信息</h1>
		</template>
		<el-form label-position="top" :rules="rules" ref="form" class="rowsInfo" label-suffix="：" label-width="80px" :model="formMem">
			<div class="flex">
				<el-form-item label="车牌号码" prop="carNo">
					<el-input v-model="formMem.carNo" placeholder="请输入车牌号码"></el-input>
				</el-form-item>
				<el-form-item label="用户类型" prop="memberTypeId">
					<el-select v-model="formMem.memberTypeId" placeholder="请选择用户类型" @change="selectMemberType">
						<el-option :label="item.name" :key="ind" v-for="(item,ind) in memberTypeList" :value="item.id"></el-option>
					</el-select>
				</el-form-item>
			</div>
			<div class="flex">
				<el-form-item label="有效期起始" prop="startDate">
					<el-date-picker
							v-model="formMem.startDate"
							type="date"
							placeholder="有效期起始">
					</el-date-picker>
				</el-form-item>
				<el-form-item label="有效期结束" prop="endDate">
					<el-date-picker
							v-model="formMem.endDate"
							type="date"
							placeholder="有效期结束">
					</el-date-picker>
				</el-form-item>
			</div>
			<el-form-item label="车主姓名" prop="carOwner">
				<el-input v-model="formMem.carOwner" placeholder="请输入车主姓名"></el-input>
			</el-form-item>
			<div class="flex">
				<el-form-item label="办理人" prop="dataHandler">
					<el-input v-model="formMem.dataHandler" placeholder="请输入办理人"></el-input>
				</el-form-item>
				<el-form-item label="办理时间" prop="applyDate">
					<el-date-picker
							v-model="formMem.applyDate"
							type="datetime"
							placeholder="办理时间">
					</el-date-picker>
				</el-form-item>
			</div>

			<div class="flex">
				<el-form-item label="身份证号">
					<el-input v-model="formMem.ownerCardNo" placeholder="请输入身份证号" ></el-input>
				</el-form-item>
				<el-form-item label="电话号码" prop="ownerTel">
					<el-input v-model="formMem.ownerTel" placeholder="请输入电话号码"></el-input>
				</el-form-item>
			</div>
			<div class="flex">
				<el-form-item label="公司名称">
					<el-input v-model="formMem.ownerCompany" placeholder="请输入公司名称"></el-input>
				</el-form-item>
				<el-form-item label="收费停车场" prop="parkId">
					<el-select v-model="formMem.parkId" placeholder="请选择收费停车场" @change="selectChanged">
						<el-option :label="item.parkingName" :key="ind" v-for="(item,ind) in parkingList" :value="item.id"></el-option>
					</el-select>
				</el-form-item>
			</div>
			<el-form-item label="备注">
				<el-input
						type="textarea"
						:rows="4"
						placeholder="请输入内容"
						v-model="formMem.remark">
				</el-input>
			</el-form-item>
		</el-form>
		<div slot="footer" class="dialog-footer">
			<el-button @click="resetForm">取 消</el-button>
			<el-button type="primary" @click="memberEdit('form')">确 定</el-button>
		</div>
	</el-dialog>

</template>

<script>
import { editMemberInfo,addMember } from "@/api/api";
import moment from "moment";

moment.locale("zh-cn");
export default {
	name: "updateMember",
	props: {
		rows: {
			type: Object
		},
		state: {
			type: String
		},
		showMember: {
			type: Boolean,
			default: false
		},
		memberTypeList:{
			type:Array
		},
		parkingList:{
			type:Array
		},
		memberId:{
			type:Number
		}
	},
	data() {
		return {
			dialogMem: this.showMember,
			statusText: this.state,
			formMem: {
				id:'',
				carNo:'',
				memberType:'',
				memberTypeId:'',
				startDate:'',
				endDate:'',
				carOwner:'',
				dataHandler:'',
				ownerCardNo:'',
				ownerTel:'',
				ownerCompany:'',
				parkId:'',
				applyDate:''
			},
			chargeParking:'',
			rules:{
				carNo: [
					{ required: true, message: '请输入车牌号码', trigger: 'change' }
				],
				memberTypeId: [
					{ required: true, message: '请选择用户类型', trigger: 'change' }
				],
				startDate: [
					{ required: true, message: '请选择有效期起始', trigger: 'change' }
				],
				endDate: [
					{ required: true, message: '请选择有效期结束', trigger: 'change' }
				],
				carOwner: [
					{ required: true, message: '请输入车主姓名', trigger: 'change' }
				],
				dataHandler: [
					{ required: true, message: '请输入办理人', trigger: 'change' }
				],
				ownerTel: [
					{ required: true, message: '请输入电话号码', trigger: 'change' }
				],
				parkId: [
					{ required: true, message: '请选择收费停车场', trigger: 'change' }
				],
			},
			user:JSON.parse(localStorage.getItem("currentUser"))
		};
	},
	mounted() {
	},
	methods: {
		selectChanged(value){
			let parkingInfo =this.parkingList.find(item=>{
				return item.id === value
			})
			this.chargeParking = parkingInfo.parkingName
		},
		selectMemberType(value){
			let memberTypeName = this.memberTypeList.find(item=>{
				return item.id === value
			})
			this.formMem.memberType = memberTypeName.name
		},
		memberEdit(formName) {
			this.$refs[formName].validate((valid) => {
				if (valid) {
					this.formMem.startDate = moment(this.formMem.startDate).format("YYYY-MM-DD HH:mm:ss");
					this.formMem.endDate = moment(this.formMem.endDate).format("YYYY-MM-DD HH:mm:ss");
					this.formMem.applyDate = moment(this.formMem.applyDate).format("YYYY-MM-DD HH:mm:ss");
					this.formMem.chargeParking = this.chargeParking
					if (this.statusText == '新增'){
						//如果是有审核权限的用户，新增时不用再去审核
						if (this.user.permissions.indexOf('33') != -1) {
							this.formMem.approvalStatus = 2
						}else {
							this.formMem.approvalStatus = 0
						}
						addMember(JSON.stringify(this.formMem)).then(res=>{
							this.$message.success(res.data.msg);
							this.initForm();
							setTimeout(() => {
								this.$emit("openMember", { "flg": false });
							}, 500);
						})
					} else {
						this.formMem.applyDate = moment(this.formMem.applyDate).format("YYYY-MM-DD HH:mm:ss");
						editMemberInfo(JSON.stringify(this.formMem)).then(res => {
							this.$message.success(res.data.msg);
							this.initForm();
							setTimeout(() => {
								this.$emit("openMember", { "flg": false });
							}, 500);
						}).catch(() => {
							this.$message.error("修改失败");
						});
					}

				} else {
					return false;
				}
			});
		},
		resetForm() {
			this.initForm()
			this.$emit("openMember", { "flg": false });
		},
		getMemberById(id){
			this.$get('/car/member-info/'+id).then(res=>{
				this.formMem = res.data.data
			})
		},
		initForm(){
			this.formMem = {
				id:'',
				carNo:'',
				memberType:'',
				memberTypeId:'',
				startDate:'',
				endDate:'',
				carOwner:'',
				dataHandler:'',
				ownerCardNo:'',
				ownerTel:'',
				ownerCompany:'',
				parkId:'',
			}
		}
	},
	watch: {
		showMember: {
			handler(newval) {
				this.dialogMem = newval;
				if (newval==true && this.state == '编辑'){
					this.getMemberById(this.memberId)
				}
			}
		},
		state: {
			handler(newval) {
				this.statusText = newval;
			}
		},
		/*dialogMem: {
			handler(newval) {
				this.getMemberById(newval)
			}
		}*/
		/*rows: {
			handler(val) {
				if (val) {
					this.formMem = JSON.parse(JSON.stringify(this.rows));
				} else {
					this.formMem = {};
				}
			},
			deep: true
		}*/
	}
};
</script>

<style lang="scss" scoped>
@import "../../assets/css/table";
</style>
